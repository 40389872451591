import { FIREBASE_PROVIDERS, baseFirebaseAuthErrorHandler, firebaseAuth } from '../index';
import {
  ConfirmationResult,
  createUserWithEmailAndPassword as firebaseSignUpWithEmail,
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword as firebaseSignInWithEmail,
  signInWithPhoneNumber,
  signInWithPopup,
  signInWithCredential,
  User,
  UserCredential,
  AuthCredential,
  linkWithPopup,
  unlink,
  ApplicationVerifier,
  confirmPasswordReset,
  applyActionCode,
} from '@firebase/auth';
import { FirebaseError } from '@firebase/app';
import { notice, ToastType } from '../../common';
import * as process from 'process';

export enum OAUTH_PROVIDER_TYPES {
  MICROSOFT = 'MICROSOFT',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

const OAUTH_PROVIDERS_HOST = {
  MICROSOFT: 'microsoft.com',
  APPLE: 'apple.com',
};

type AuthWithEmailAndPasswordMethod = (params: { email: string; password: string }) => Promise<UserCredential | void>;
type AuthWithOauthPopupByProvider = (provider: OAUTH_PROVIDER_TYPES) => Promise<UserCredential | void>;

export const createFirebaseService = () => {
  const getProviderByProviderHost = (providerHost: OAUTH_PROVIDER_TYPES) => {
    if (providerHost === OAUTH_PROVIDER_TYPES.GOOGLE) {
      return new GoogleAuthProvider();
    }

    return new OAuthProvider(OAUTH_PROVIDERS_HOST[providerHost]);
  };

  const signInWithEmail: AuthWithEmailAndPasswordMethod = async ({ email, password }) => {
    try {
      return await firebaseSignInWithEmail(firebaseAuth, email, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        baseFirebaseAuthErrorHandler(error);
        return;
      }
      throw error;
    }
  };

  const signUpWithEmail: AuthWithEmailAndPasswordMethod = async ({ email, password }) => {
    try {
      return await firebaseSignUpWithEmail(firebaseAuth, email, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        baseFirebaseAuthErrorHandler(error);
        return;
      }
      throw error;
    }
  };

  const authWithPopupByProvider: AuthWithOauthPopupByProvider = async providerHost => {
    try {
      const provider = getProviderByProviderHost(providerHost);
      return await signInWithPopup(firebaseAuth, provider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return baseFirebaseAuthErrorHandler(error, providerHost);
      }
      throw error;
    }
  };

  const verifyUsersEmail = async (firebaseUser: User, noticeMessage?: string) => {
    try {
      await sendEmailVerification(firebaseUser);
      noticeMessage &&
        notice(ToastType.SUCCESS, noticeMessage, {
          hideProgressBar: false,
          autoClose: 8000,
        });
    } catch (error) {
      if (error instanceof FirebaseError) {
        return baseFirebaseAuthErrorHandler(error);
      }
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const sendVerificationCodeOnPhoneNumber = async (
    phoneNumber: string,
    firebaseAppVerifier: ApplicationVerifier
  ): Promise<ConfirmationResult | void> => {
    try {
      return await signInWithPhoneNumber(firebaseAuth, phoneNumber, firebaseAppVerifier);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return baseFirebaseAuthErrorHandler(error);
      }
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const authWithCredential = async (authCredential: AuthCredential): Promise<UserCredential | void> => {
    try {
      return await signInWithCredential(firebaseAuth, authCredential);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return baseFirebaseAuthErrorHandler(error);
      }
      notice(ToastType.ERROR, 'Something went wrong , please try again!');
    }
  };

  const linkWithProvider = async (providerHost: OAUTH_PROVIDER_TYPES) => {
    const provider = getProviderByProviderHost(providerHost);

    return await linkWithPopup(firebaseAuth.currentUser, provider);
  };

  const unlinkWithProvider = async (providerId: FIREBASE_PROVIDERS) => {
    return await unlink(firebaseAuth.currentUser, providerId);
  };

  const confirmResetPassword = async (code: string, newPassword: string) => {
    return await confirmPasswordReset(firebaseAuth, code, newPassword);
  };

  const confirmEmail = async (code: string) => {
    return await applyActionCode(firebaseAuth, code);
  };

  return {
    confirmEmail,
    signInWithEmail,
    signUpWithEmail,
    authWithPopupByProvider,
    sendVerificationCodeOnPhoneNumber,
    authWithCredential,
    verifyUsersEmail,
    linkWithProvider,
    unlinkWithProvider,
    confirmResetPassword,
  };
};
